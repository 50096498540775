import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {UserService} from 'app/core/user/user.service';
import {catchError, Observable, of, switchMap, throwError} from 'rxjs';
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class AuthService {
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post(environment.apiUrl + '/api/v1/auth/forgot-password', {email: email});
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(newPassword: string, confirmNewPassword: string, resetToken: string): Observable<any> {
        return this._httpClient.post(environment.apiUrl + '/api/v1/auth/reset-password', {
            newPassword: newPassword,
            confirmNewPassword: confirmNewPassword,
            token: resetToken,
        });
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(environment.apiUrl + `/api/v1/auth/sign-in`, credentials).pipe(
            switchMap((response: any) => {
                console.log(response)
                // Store the access token in the local storage
                this.accessToken = response.data.access_token;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.data.user;

                // Return a new observable with the response
                return of(response.data);
            }),
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient.post(environment.apiUrl + '/api/v1/user-info-with-token', {
            token: this.accessToken,
        }, {
            headers: {
                Authorization: 'Bearer ' + this.accessToken
            }
        }).pipe(
            catchError(() =>

                // Return false
                of(false),
            ),
            switchMap((response: any) => {
                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                // if (response.accessToken) {
                //     this.accessToken = response.data.accessToken;
                // }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.data.user;

                // Return true
                return of(true);
            }),
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { firstName: string; lastnName: string; email: string; password: string }): Observable<any> {
        // return this._httpClient.post('api/auth/sign-up', user);
        return this._httpClient.post<any>(environment.apiUrl + `/api/v1/auth/sign-up`, user);

    }

    verifyToken(token: string): Observable<any> {
        // return this._httpClient.post('api/auth/sign-up', user);
        return this._httpClient.post<any>(environment.apiUrl + `/api/v1/auth/verify`, {token: token})
            .pipe(
                catchError((ex) => {
                        return of(false);
                    }
                ),
                switchMap((response: any) => {
                    if (response) {
                        console.log(response)
                        // Replace the access token with the new one if it's available on
                        // the response object.
                        //
                        // This is an added optional step for better security. Once you sign
                        // in using the token, you should generate a new one on the server
                        // side and attach it to the response object. Then the following
                        // piece of code can replace the token with the refreshed one.
                        if (response.data.accessToken) {
                            this.accessToken = response.data.accessToken;
                        }

                        // Set the authenticated flag to true
                        this._authenticated = true;

                        // Store the user on the user service
                        this._userService.user = response.data.user;

                        // Return true
                        return of(true);
                    } else {
                        return of(false);
                    }


                }),
            );
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    checkVerifyToken(token: string): Observable<boolean> {
        return this.verifyToken(token);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();
    }


}
