/* eslint-disable */
import {FuseNavigationItem} from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'application',
        title: 'Application',
        subtitle: 'Build serverless functions & deploy websites and full-stack applications',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'project.fullstack',
                title: 'Project',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-document-check',
                link: '/project'
            },
            ]
    },
    {
        id: 'saas',
        title: 'SaaS',
        subtitle: 'Increase security, performance, and reliability of your domains.',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'saas.custom-hostname',
                title: 'Custom Hostnames',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/saas/custom-hostname'
            },
            {
                id: 'saas.custom-waf',
                title: 'Custom WAF',
                type: 'basic',
                icon: 'heroicons_outline:arrow-left-on-rectangle',
                link: '/saas/custom-waf'
            },
            {
                id   : 'saas.pricing',
                title: 'Plans',
                type : 'basic',
                icon    : 'heroicons_outline:banknotes',
                link : '/saas/pricing',
            }
            ]
    }


];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'custom-hostname',
        title: 'Custom Hostname',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/apps/custom-hostname'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'custom-hostname',
        title: 'Custom Hostname',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/apps/custom-hostname'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'custom-hostname',
        title: 'Custom Hostname',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/apps/custom-hostname'
    }
];

export const landingNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'basic',
        link: '/home'
    },
    {
        id: 'about-us',
        title: 'About Us',
        type: 'basic',
        link: '/about'
    },
    {
        id: 'login',
        title: 'Login',
        type: 'basic',
        link: '/sign-in'
    },
    {
        id: 'solution',
        title: 'Solution',
        type: 'basic',
        link: '/solution'
    },
    {
        id: 'contact',
        title: 'Contact',
        type: 'basic',
        link: '/contact'
    }
];
