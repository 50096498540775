import {Route} from '@angular/router';
import {initialDataResolver, initialLandingDataResolver} from 'app/app.resolvers';
import {AuthGuard} from 'app/core/auth/guards/auth.guard';
import {NoAuthGuard} from 'app/core/auth/guards/noAuth.guard';
import {LayoutComponent} from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch: 'full', redirectTo: 'home'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'saas/custom-hostname'},

    // Auth routes for guests
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        resolve: {
            initialData: initialLandingDataResolver
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes')},

            {
                path: 'verify',
                loadChildren: () => import('app/modules/auth/verify-token/verify-token.routes')
            },
            {
                path: 'confirmation-required',
                loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')
            },
            {
                path: 'forgot-password',
                loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')
            },
            {
                path: 'reset-password',
                loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')
            },
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')},
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
            {
                path: 'unlock-session',
                loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')
            }
        ]
    },

    // Landing routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        data: {
            layout: 'classy'
        },
        children: [
            {
                path: 'saas',
                children: [
                    {
                        path: 'custom-hostname',
                        loadChildren: () => import('app/modules/apps/saas/custom-hostname/custom-hostname.routes')
                    },
                    {
                        path: 'custom-waf',
                        loadChildren: () => import('app/modules/apps/saas/custom-waf/custom-waf.routes')
                    },
                    {path: 'pricing', loadChildren: () => import('app/modules/apps/saas/pricing/table/table.routes')},
                ]
            },
            {
                path: 'project',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/apps/project/project.routes')
                    },
                ]
            },
            {path: 'settings', loadChildren: () => import('app/modules/apps/settings/settings.routes')},

            // 404 & Catch all
            {
                path: '404-not-found',
                pathMatch: 'full',
                loadChildren: () => import('app/modules/apps/pages/error/error-404/error-404.routes')
            },
            {path: '**', redirectTo: '404-not-found'}
        ]
    }
];
